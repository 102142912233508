// 3rd party
import { useState, useEffect } from 'react'

// Components
import Icon from '@/components/Icon/Icon'

const CartButton = ({ cart, cartModalViewHandler }) => {
  const [itemCount, setItemCount] = useState(0)
  useEffect(() => {
    let count = 0
    cart?.all_cart_items.forEach((element) => {
      count = count + element.quantity
    })
    setItemCount(count)
  }, [cart])
  return itemCount ? (
    <div
      className="relative cursor-pointer hover:fill-primary hover:text-[black]"
      onClick={cartModalViewHandler}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          cartModalViewHandler()
        }
      }}
      tabIndex={0}
    >
      <Icon
        className={`cursor-pointer fill-white transition-all hover:fill-primary`}
        viewBox={`0 0 25 25`}
        size={26}
        icon="cartNoFill"
        count={itemCount > 0 ? itemCount : 0}
      />
      <span
        className=""
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '25px',
          height: '25px',
          fontSize: '11px',
          top: '2px',
          left: '0px',
          color: 'white',
        }}
      >
        {itemCount}
      </span>
    </div>
  ) : (
    <div
      onClick={cartModalViewHandler}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          cartModalViewHandler()
        }
      }}
      tabIndex={0}
    >
      <Icon
        className={`cursor-pointer fill-white transition-all hover:fill-primary`}
        viewBox={`0 0 25 25`}
        size={26}
        icon="cartNoFill"
        count={(cart?.all_cart_items?.length || 0) > 0 ? cart.all_cart_items.length : 0}
      />
    </div>
  )
}

export default CartButton
