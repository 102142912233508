const TopLevelMenus = ({
  contentBlocks,
  handleNavItemClick,
  selectedCategory,
  setPreventClickOutsideClose,
}) => (
  <ul className="flex flex-row items-center xl:gap-[48px] gap-[28px] h-[68px]">
    {contentBlocks.items?.map((category, i) => {
      return (
        <li key={`menu-category-${category?.navigationTitle}-${i}`}>
          <a
            href={category?.navigationLink}
            tabIndex={0}
            onClick={(e) => {
              handleNavItemClick(category, e)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleNavItemClick(category, e)
              }
            }}
            onMouseEnter={() => {
              setPreventClickOutsideClose(true)
            }}
            onMouseLeave={() => {
              setPreventClickOutsideClose(false)
            }}
            className={`text-center xl:max-w-none max-w-[120px] break-words uppercase font-semibold xl:text-[13px] text-[12px] leading-[16.25px]
              tracking-[0.2em] h-full flex items-center cursor-pointer transition-all underline hover:decoration-white decoration-2 decoration-transparent underline-offset-4  ${
                selectedCategory !== category?.sys?.id && selectedCategory !== null
                  ? 'text-gray-500 hover:decoration-white'
                  : selectedCategory !== null
                  ? 'border-b-transparent text-white underline'
                  : 'text-white'
              }`}
          >
            {category?.navigationTitle}
          </a>
        </li>
      )
    })}
  </ul>
)

export default TopLevelMenus
